import posthog from "posthog-js";
import websocketService from "@puraidoco/agenthq-sdk/websocket";

export function initAnalytics() {
  console.log("Init PH");
  posthog.init("phc_SchO0fA6DPQmXOmOsycPycNZ4Dbw8pDLk87iObcz3d5", {
    api_host: "https://us.i.posthog.com",
    person_profiles: "identified_only",
    autocapture: false,
    capture_pageview: false,
    enable_heatmaps: false,
  });
}

export function trackPageView() {
  posthog.capture("$pageview");
}

export function identifyUser(
  userId: string,
  email: string | null,
  displayName: string,
  theme?: string
) {
  posthog.identify(userId, { email, name: displayName, theme });
}

let lastTimer: ReturnType<typeof setTimeout> | null = null; // NodeJS compatibility.

export function wsTrackClientEvent(
  event: { friendlyName: string },
  displayText: string, // Ensure a friendly name is provided.
  properties: Record<string, any> = {}
) {
  // This exists to prevent capturing events that don't last long enough to be useful.
  if (lastTimer) {
    clearTimeout(lastTimer);
  }

  properties.displayText = displayText;

  lastTimer = setTimeout(() => {
    websocketService.send({
      type: "EVENT",
      payload: {
        event_name: event.friendlyName,
        event_payload: properties,
      },
    });
  }, 1000);
}

export function phTrackClientEvent(
  event: { name: string },
  properties: Record<string, any>
) {
  // console.log("trackClientEvent", event, properties);
  posthog.capture(event.name, properties);
}

export function setClientProperty(key: string, value: any) {
  posthog.people.set({ [key]: value });
}

export function setPersonPropertiesForFlags(properties: Record<string, any>) {
  posthog.setPersonPropertiesForFlags(properties);
}
